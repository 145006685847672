import UAParser from "ua-parser-js"

export function useDeviceInfo() {
  // register an event on page load with browser information from ua-parser-js
  const parser = new UAParser()
  const browser = parser.getBrowser()
  const os = parser.getOS()
  const device = parser.getDevice()
  const ua = parser.getUA()

  // color depth
  const colorDepth = window.screen.colorDepth

  // Webgl
  const canvas = document.createElement("canvas")
  const gl = canvas.getContext("webgl")

  const renderer = gl.getParameter(gl.RENDERER)
  const vendor = gl.getParameter(gl.VENDOR)
  const debugInfo = gl.getExtension("WEBGL_debug_renderer_info")

  let debugRenderer = null
  if (debugInfo) {
    debugRenderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL)
  }

  function webGLDetails() {
    const capabilitiesToTest = [
      "MAX_VERTEX_ATTRIBS",
      "MAX_VERTEX_UNIFORM_VECTORS",
      "MAX_VERTEX_TEXTURE_IMAGE_UNITS",
      "MAX_VARYING_VECTORS",
      "VERTEX_SHADER",
      "MAX_VERTEX_UNIFORM_COMPONENTS",
      "MAX_VERTEX_UNIFORM_BLOCKS",
      "MAX_VERTEX_OUTPUT_COMPONENTS",
      "MAX_VARYING_COMPONENTS",
    ]

    const canvasWebGl1 = document.createElement("canvas")
    const canvasWebGl2 = document.createElement("canvas")

    const capabilities = {}
    const webglContext = canvasWebGl1.getContext("webgl")
    if (!webglContext) {
      return capabilities
    }
    const webgl2Context = canvasWebGl2.getContext("webgl2")
    const context = webgl2Context || webglContext

    for (const capability of capabilitiesToTest) {
      capabilities[capability] = context.getParameter(context[capability])
    }
    return {
      version: context.getParameter(context.VERSION),
      ...capabilities,
    }
  }

  // Get battery info (level and charging)
  async function batteryInfo() {
    if (!navigator.getBattery) {
      return
    }
    const battery = await navigator.getBattery()
    return {
      level: `${battery.level * 100}%`,
      charging: battery.charging,
    }
  }

  async function getInfo() {
    return {
      browser_name: browser.name,
      browser_version: browser.version,
      os_name: os.name,
      os_version: os.version,
      device_model: device.model,
      device_type: device.type,
      ua,
      webGL: {
        renderer,
        vendor,
        debugRenderer,
        details: webGLDetails(),
      },
      mediaCapabilities: {
        decoding: await decodingMediaCapabilities(),
      },
      hardware: {
        ram: navigator.deviceMemory,
        cpuCount: navigator.hardwareConcurrency,
        platform: navigator.platform,
        battery: await batteryInfo(),
      },
      pointerCapabilities: getPointerCapabilities(),
      colorDepth,
      screen: {
        width: window.screen.width,
        height: window.screen.height,
        pixelDensity: window.devicePixelRatio,
      },
    }
  }

  async function decodingMediaCapabilities() {
    const decodingMediaCapabilitiesTest = {}

    if (!navigator.mediaCapabilities) {
      console.log("Media Capabilities API is not supported in this browser.")
      return
    }

    const mediaConfigs = [
      { label: "VP8", config: { type: "file", video: { contentType: "video/webm; codecs=\"vp8\"", width: 1920, height: 1080, bitrate: 100000, framerate: 30 } } },
      { label: "H.264", config: { type: "file", video: { contentType: "video/mp4; codecs=\"avc1.42E01E\"", width: 1920, height: 1080, bitrate: 100000, framerate: 30 } } },
      { label: "HEVC", config: { type: "file", video: { contentType: "video/mp4; codecs=\"hevc\"", width: 3840, height: 2160, bitrate: 35000000, framerate: 60 } } },
    ]

    for (const mediaConfig of mediaConfigs) {
      try {
        const result = await navigator.mediaCapabilities.decodingInfo(mediaConfig.config)
        decodingMediaCapabilitiesTest[mediaConfig.label] = {
          supported: result.supported,
          smooth: result.smooth,
          powerEfficient: result.powerEfficient,
        }
      } catch (error) {
        decodingMediaCapabilitiesTest[mediaConfig.label] = { error: error.message }
      }
    }
    return decodingMediaCapabilitiesTest
  }

  function getPointerCapabilities() {
    const hasFinePointer = matchMedia("(pointer: fine)").matches
    const canHover = matchMedia("(hover: hover)").matches
    return { hasFinePointer, canHover }
  }

  return {
    getInfo,
  }
}
