<template>
  <v-menu
    open-on-hover
    :nudge-width="200"
    offset-y
    :close-on-content-click="false"
  >
    <template #activator="{ on }">
      <v-icon
        v-on="on"
        small
        rigth
        class="ml-2 left"
      >
        info_outline
      </v-icon>
    </template>

    <v-card max-width="600">
      <v-card-title v-if="isDisplayed">
        {{ $t('live.remainingTime') }} {{ remainingTimeFormatted.value }}
      </v-card-title>
      <v-card-title v-else>
        {{ "Informations" }}
      </v-card-title>
      <v-card-text>
        <real-time-clock class="text-bold">
          <template slot-scope="time">
            {{ $t('live.currentTime') }} {{ time.clock }}
          </template>
        </real-time-clock>

        <div v-if="quiz.automatic_start">
          {{ $t('live.remainingTimeTips') }}
        </div>

        <quiz-student-info
          quiz-type="live"
          :show-language-switch="false"
          :quiz="quiz"
          :school-user="schoolsUser"
          :quizzes-attempt-summary="quizzesAttemptSummary"
        />
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import RealTimeClock from "./real_time_clock.vue"
import QuizStudentInfo from "../onboarding/quiz_student_info.vue"
import { useQuizzesAttemptSummary } from "@/composables/useQuizzesAttemptSummary"

export default {
  name: "QuizStudentMenuInfo",
  components: { RealTimeClock, QuizStudentInfo },
  props: {
    instance: {
      type: Object,
      required: true,
    },
    quiz: {
      type: Object,
      required: true,
    },
    schoolsUser: {
      type: Object,
      required: true,
    },
    quizzesAttemptSummary: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    intervalMethod: null,
  }),
  computed: {
    isDisplayed() {
      return !!this.quizzesAttemptSummary.submission_deadline_at
    },
    remainingTimeFormatted() {
      return useQuizzesAttemptSummary(this.quizzesAttemptSummary).remainingTimeFormatted
    },
  },

}
</script>

<style>

</style>
