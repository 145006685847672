<template>
  <div>
    <v-row
      justify="center"
      align="center"
      class="full_height_row"
    >
      <v-col class="col-md-8 col-sm-12">
        <v-card
          flat
          color="transparent"
          v-if="showSessions"
        >
          <v-card-title>
            {{ $t("live.examOnboarding.sessions.title") }}
          </v-card-title>
          <same-quiz-sessions-list
            :quiz="quiz"
          />
        </v-card>
        <preloader
          v-else
          class="center d-block"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import quizStudentMixin from "../../quizzes/quiz_student_mixin"
import redirectToScorePageMixin from "./redirect_to_score_page_mixin"
import EventsMixin from "../../../components/shared/events_mixin"
import SameQuizSessionsList from "@/components/quiz/onboarding/sameQuizSessionsList.vue"
import { mapActions, mapGetters } from "vuex"
import Preloader from "@/components/shared/preloader.vue"

export default {
  name: "EvalmeeSessionSelectionView",
  components: { Preloader, SameQuizSessionsList },
  mixins: [quizStudentMixin, redirectToScorePageMixin, EventsMixin],
  computed: {
    ...mapGetters(["quizzesAttemptSummaries"]),
    showSessions() {
      if(!this.quiz) return false
      if(!this.quizzesAttemptSummaryId) return true
      return !!this.quizzesAttemptSummary
    },
  },
  methods:{
    ...mapActions(["fetchQuizzesAttemptSummaries"]),
    redirectOrFetchAttemptInSameQuiz() {
      if(!this.quizzesAttemptSummaryId) {
        this.fetchQuizzesAttemptsSummariesInSameQuiz().then(this.redirectToFirstQuizzesAttempt)
      }

      if(!this.quizzesAttemptSummary) return
      if(this.quizzesAttemptSummary.is_subscribed_to_other_sessions_in_same_quiz) {
        this.fetchQuizzesAttemptsSummariesInSameQuiz()
      } else {
        this.$router.replace({ name: "onboarding", params: this.$route.params })
      }
    },
    redirectToFirstQuizzesAttempt() {
      const firstAttemptInQuiz = this.quizzesAttemptSummaries.find(s => s.quiz_id === this.quiz.id)
      this.$router.replace({ params: { ...this.$route.params, quizzesAttemptSummaryId: firstAttemptInQuiz.id } })
    },
  },
  watch: {
    quizzesAttemptSummaryId: {
      handler() {
        this.redirectOrFetchAttemptInSameQuiz()
      },
      immediate: true,
    },
  },
}
</script>

