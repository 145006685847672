<template>
  <router-view />
</template>

<script>
import { mapActions } from "vuex"
import ws from "../../web_sockets"
import helpers from "../../helpers"
import api from "../../api"
import quizStudentMixin from "@/views/quizzes/quiz_student_mixin"

export default {
  name: "EvalmeeStudentQuiz",
  mixins: [quizStudentMixin],
  props: {
    quizId: { type: Number, default: null },
    quizzesAttemptSummaryId: { type: Number, default: null },
  },
  computed: {
  },
  methods: {
    ...mapActions([
      "fetchQuizzesAttemptSummary",
      "fetchQuiz",
      "newAppNotification",
    ]),
  },
  watch: {
    quizId: {
      handler() {
        ws.channels
          .StudentsQuiz
          .subscribe({ quizId: this.quizId })
      },
      immediate: true,
    },
  },
  mounted() {
    this.fetchQuizzesAttemptSummary(this.quizzesAttemptSummaryId)
      .catch((error) => {
        // if the quiz attempt summary is not found, redirect to the session selection page
        // this can happen if the attempts was deleted by the teacher with a quiz reset when
        // email invitation are already sent
        if(error.response.status === 404) {
          this.$router.push({ name: "sessionsSelection", params: { quizId: this.quizId } })
        }
      })

    this.fetchQuiz(this.quizId)
      .catch(() => {
        this.newAppNotification({
          message: this.$t("live.examOnboarding.quiz.fetchError"),
          type: "error",
          timeout: 10000,
        })
      })
  },
  created() {
    if(helpers.isInIframe()) {
      const path = this.$router.resolve({
        name: "LTIStudent",
        params: { quizId: this.quizId },
      }).href
      const url = `${api.legacy.evalmeeStudentUrl()}/${path}`
      window.self.location = url
    }
  },
}
</script>

