<template>
  <div
    v-if="quiz.enable_success_level_students && quiz.enable_success_level"
    class="center-align mt-8 mb-8"
  >
    <div class="text-h4 my-3">
      {{ successLevelFromGrade(parseInt(grade)).name }}
    </div>
    <div class="text-h5 my-3">
      {{ successLevelFromGrade(parseInt(grade)).comment }}
    </div>
  </div>
</template>

<script>
import successLevelFromGradeMixin
  from "../../shared/success_level_from_grade_mixin"

export default {
  name: "SuccessLevelStudent",
  mixins: [successLevelFromGradeMixin],
  props: {
    grade: {
      type: String,
      required: true,
    },
    quiz: {
      type: Object,
      required: true,
    },
  },
}
</script>
