<template>
  <div>
    <v-window v-model="onboarding">
      <v-window-item
        v-for="n in length"
        :key="`card-${n}`"
      >
        <v-card
          flat
          color="transparent"
        >
          <v-row
            class="fill-height "
            align="center"
            justify="center"
            v-if="onboardingContent[n-1]"
          >
            <v-card-title>
              {{ onboardingContent[n-1].title }}
            </v-card-title>
            <v-card-text
              v-if="onboardingContent[n-1].content"
              class="fill-height"
            >
              <div
                v-html="onboardingContent[n-1].content"
                class="center_and_fit_content onboarding-quiz-content"
              />
            </v-card-text>
            <v-card-text>
              <component
                v-if="onboardingContent[n-1].component"
                :is="onboardingContent[n-1].component"
                v-bind="onboardingContent[n-1].componentProps"
                @videoCalibrationDone="updateVideoCalibrationDone"
                @idCardVerificationDone="updateIdCardVerificationDone"
                @asAnActiveCamera="(val) => asAnActiveCamera = val"
                :quiz="quiz"
                :school-user="schoolUser"
                :quizzes-attempt-summary.sync="tempQuizzesAttemptSummary"
                @save:quizzes-attempt-summary="saveQuizzesAttemptSummary"
              />
            </v-card-text>
            <eva-button
              primary
              class="mb-5"
              @click="onboardingContent[n-1].nextButtonMethod()"
              :disabled="onboardingContent[n-1].nextButtonDisabled"
              :label="onboardingContent[n-1].nextButtonTitle"
            />
          </v-row>
        </v-card>
      </v-window-item>
    </v-window>

    <progressDots
      :max-steps="length"
      :step="onboarding"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import QuizCameraTest from "./quiz_camera_test.vue"
import QuizStudentInfo from "./quiz_student_info.vue"
import QuizOnboardingUserAgreement from "./quiz_onboarding_user_agreement.vue"
import QuizOnboardingUserDataAgreement from "./quiz_onboarding_user_data_agreement.vue"
import ProgressDots from "../../shared/progress-dots.vue"
import { useQuizzesAttemptSummary } from "@/composables/useQuizzesAttemptSummary"

export default {
  name: "QuizOnboarding",
  components: {
    QuizOnboardingUserAgreement,
    QuizOnboardingUserDataAgreement,
    QuizStudentInfo,
    QuizCameraTest,
    ProgressDots,
  },
  props: {
    quiz: { type: Object, required: true },
    schoolUser: { type: Object, required: true },
    quizzesAttemptSummary: { type: Object, required: true },
  },
  data() {
    return {
      onboarding: 0,
      videoCalibrationDone: false,
      idCardVerificationDone: false,
      asAnActiveCamera: false,
      tempQuizzesAttemptSummary: {},
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
    length() {
      return this.onboardingContent.length
    },
    onboardingContent() {
      return this.onboardingContentBuilder.filter(x => x.show === true)
    },
    onboardingContentBuilder() {
      return [
        {
          title: `${this.$t("live.examOnboarding.ready", { name: this.currentUser.name })}`,
          component: "QuizStudentInfo",
          nextButtonTitle: this.$t("live.examOnboarding.start"),
          nextButtonMethod: this.startQuiz,
          nextButtonDisabled: !this.quiz.active,
          show: this.quiz.quiz_type !== "exam",
        },
        {
          title: `${this.$t("live.examOnboarding.ready", { name: this.currentUser.name })}`,
          component: "QuizStudentInfo",
          nextButtonTitle: this.$t("live.examOnboarding.understood"),
          nextButtonMethod: this.next,
          nextButtonDisabled: false,
          show: this.quiz.quiz_type === "exam",
        },
        {
          title: this.$t("live.examOnboarding.preparations.title"),
          content: this.$t("live.examOnboarding.preparations.content"),
          nextButtonTitle: this.$t("live.examOnboarding.understood"),
          nextButtonMethod: this.next,
          nextButtonDisabled: false,
          show: this.quiz.quiz_type === "exam",
        },
        {
          title: this.$t("live.examOnboarding.conditions.title"),
          component: "QuizOnboardingUserDataAgreement",
          nextButtonTitle: this.$t("live.examOnboarding.understood"),
          nextButtonMethod: this.quiz.video_proctoring ? this.saveQuizzesAttemptSummaryAndNext : this.next,
          nextButtonDisabled: (
            !this.quizzesAttemptSummary?.user_data_usage_accepted_at && this.quiz.video_proctoring ||
            !this.quizzesAttemptSummary?.similarity_tool_eula_accepted_at && this.quiz.similarity_tool_enabled
          ),
          show: this.quiz.quiz_type === "exam" && (this.quiz.require_fullscreen || this.quiz.detect_mouse_leave),
        },
        {
          title: this.$t("live.examOnboarding.notPublished.title"),
          content: this.$t("live.examOnboarding.notPublished.content"),
          nextButtonTitle: this.$t("live.examOnboarding.understood"),
          nextButtonMethod: this.next,
          nextButtonDisabled: true,
          show: !["published", "active", "preview"].includes(this.quiz.state),
        },
        {
          title: this.$t("live.examOnboarding.webcamTest.title"),
          content: this.$t("live.examOnboarding.webcamTest.content"),
          component: "QuizCameraTest",
          componentProps: { mode: "cameraSelection", quiz: this.quiz },
          nextButtonTitle: this.$t("live.examOnboarding.webcamTest.confirmation"),
          nextButtonMethod: this.next,
          nextButtonDisabled: !this.asAnActiveCamera,
          show: this.quiz.video_proctoring,
        },
        {
          title: this.$t("live.examOnboarding.identity.title"),
          content: this.$t("live.examOnboarding.identity.content"),
          component: "QuizCameraTest",
          componentProps: { mode: "idCard", quiz: this.quiz },
          nextButtonTitle: this.$t("live.examOnboarding.next"),
          nextButtonMethod: this.next,
          nextButtonDisabled: !this.idCardVerificationDone,
          show: this.quiz.video_proctoring && this.quiz.id_card_check,
        },
        {
          title: this.$t("live.examOnboarding.photo.title"),
          content: this.$t("live.examOnboarding.photo.content"),
          component: "QuizCameraTest",
          componentProps: { mode: "referencePhoto", quiz: this.quiz },
          nextButtonTitle: this.$t("live.examOnboarding.next"),
          nextButtonMethod: this.nextAndActivateProctoringPreview,
          nextButtonDisabled: !this.videoCalibrationDone,
          show: this.quiz.video_proctoring,
        },
        {
          title: "Partage d'écran",
          content: "Merci d'accepter le partage",
          nextButtonTitle: this.$t("live.examOnboarding.next"),
          nextButtonMethod: this.next,
          show: this.quiz.video_proctoring && this.quiz.screen_proctoring,
        },
        {
          title: this.$t("live.examOnboarding.microphone.title"),
          content: this.$t("live.examOnboarding.microphone.content"),
          nextButtonTitle: this.$t("live.examOnboarding.next"),
          nextButtonMethod: this.next,
          show: this.quiz.video_proctoring,
        },
        {
          title: this.$t("live.examOnboarding.agreement.title"),
          component: "QuizOnboardingUserAgreement",
          nextButtonTitle: this.$t("live.examOnboarding.start"),
          nextButtonMethod: this.startQuiz,
          nextButtonDisabled: !this.isStartAllowed,
          show: this.quiz.quiz_type === "exam",
        },
      ]
    },
    isStartAllowed() {
      if(this.quizzesAttemptSummary?.session_id) {
        if(this.expectedSessionState.value !== "open") return false
      } else {
        if (!["active", "preview"].includes(this.quiz.state)) return false
      }

      if(!(typeof this.quizzesAttemptSummary?.user_agreement_accepted_at === "string")) return false //should be a date string

      if (this.quiz.video_proctoring) return this.quizzesAttemptSummary?.user_data_usage_accepted_at

      return true
    },
    expectedSessionState() {
      return useQuizzesAttemptSummary(this.quizzesAttemptSummary).expectedSessionState
    },
  },
  methods: {
    ...mapActions([
      "setShowCamera",
      "updateQuizzesAttemptSummary",
    ]),
    next() {
      this.onboarding += 1
    },
    startQuiz() {
      this.$emit("startQuiz")
    },
    saveQuizzesAttemptSummaryAndNext() {
      this.saveQuizzesAttemptSummary().then(this.next)
    },
    saveQuizzesAttemptSummary() {
      return this.updateQuizzesAttemptSummary(this.tempQuizzesAttemptSummary)
    },
    updateVideoCalibrationDone(val) {
      this.videoCalibrationDone = val
    },
    updateIdCardVerificationDone(val) {
      this.idCardVerificationDone = val
    },
    nextAndActivateProctoringPreview() {
      this.next()
      this.setShowCamera(true)
    },
  },
  watch: {
    quizzesAttemptSummary: {
      handler(val) {
        this.tempQuizzesAttemptSummary = val
      },
      immediate: true,
    },
  },
}
</script>

<style scoped>
 .onboarding-quiz-content{
   max-width: 400px;
 }
</style>
